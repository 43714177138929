import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import routes from "../_routes/routes";
import "../_assets/scss/default-layout.scss";
import { Header, Sidebar } from "../PostLogin/_components";
import { HIDE_HEADER_PAGES, HIDE_SIDEBAR_PAGES } from "../_constants";

class DefaultLayout extends Component {
  isUserLoggedIn = () => {
    return localStorage.getItem("userData") != null;
  };

  createRoutes = () => {
    const retData = routes.map((route, index) => {
      return route.component ? (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          name={route.name}
          render={(props) => <route.component {...props} />}
        />
      ) : null;
    });
    return [
      ...retData,
      <Route
        path="*"
        render={() => {
          return <Redirect to="/postlogin/dashboard" />;
        }}
      />,
    ];
  };

  render() {
    const isHeaderHide = HIDE_HEADER_PAGES.find((page) =>
      window.location.pathname?.includes(page)
    );

    const isSiderbarHide = HIDE_SIDEBAR_PAGES.find((page) =>
      window.location.pathname?.includes(page)
    );

    return (
      <div className="theme-white fixed-header fixed-sidebar main-container default-main-container">
        {isHeaderHide ? <></> : <Header {...this.props} />}
        <div className="main">
          {isSiderbarHide ? <></> : <Sidebar {...this.props} />}
          <div className="content-container">
            <Switch>{this.createRoutes()}</Switch>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

const connectedDefaultLayout = connect(mapStateToProps)(DefaultLayout);

export { connectedDefaultLayout as DefaultLayout };
