import { createSlice } from "@reduxjs/toolkit";
import {
  createFeedback,
  getDownloadFile,
  getResponseOfQuestionnaire,
  outroCallBackApi,
  getAllOnboardingQuestions,
  getOnboardingQuestionAnswers,
} from "Redux-Store/FeedBack/FeedBackThunk";
import { status } from "../../_constants";

const FeedBackSlice = createSlice({
  name: "feedback",
  initialState: {
    createFeedbackData: {
      status: null,
      data: {},
    },
    responsesQuestionnaireData: {
      status: null,
      data: {},
    },
    downloadFileUrlData: {
      status: null,
      data: {},
    },
    outroCallBackApiData: {
      status: null,
      data: {},
    },
    allOnboardingQuestionsData: {
      status: null,
      data: [],
    },
    onBoardingQuestionAnswers: {
      status: null,
      data: [],
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createFeedback.pending.toString(), (state, action) => {
        return {
          ...state,
          createFeedbackData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(createFeedback.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          createFeedbackData: {
            status: status.SUCCESS,
            data: payload.data,
          },
        };
      })
      .addCase(createFeedback.rejected.toString(), (state, action) => {
        return {
          ...state,
          createFeedbackData: {
            status: status.FAILURE,
            data: action.error,
          },
        };
      })

      .addCase(
        getResponseOfQuestionnaire.pending.toString(),
        (state, action) => {
          return {
            ...state,
            responsesQuestionnaireData: {
              status: status.IN_PROGRESS,
            },
          };
        }
      )
      .addCase(
        getResponseOfQuestionnaire.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            responsesQuestionnaireData: {
              status: status.SUCCESS,
              data: payload.data,
            },
          };
        }
      )
      .addCase(
        getResponseOfQuestionnaire.rejected.toString(),
        (state, { error }) => {
          return {
            ...state,
            responsesQuestionnaireData: {
              status: status.FAILURE,
              data: error?.message,
            },
          };
        }
      )

      .addCase(getDownloadFile.pending.toString(), (state, action) => {
        return {
          ...state,
          downloadFileUrlData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(getDownloadFile.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          downloadFileUrlData: {
            status: status.SUCCESS,
            data: payload,
          },
        };
      })
      .addCase(getDownloadFile.rejected.toString(), (state, action) => {
        return {
          ...state,
          downloadFileUrlData: {
            status: status.FAILURE,
            data: action.error,
          },
        };
      })

      .addCase(outroCallBackApi.pending.toString(), (state, action) => {
        return {
          ...state,
          outroCallBackApiData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(outroCallBackApi.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          outroCallBackApiData: {
            status: status.SUCCESS,
            data: payload,
          },
        };
      })
      .addCase(outroCallBackApi.rejected.toString(), (state, action) => {
        return {
          ...state,
          outroCallBackApiData: {
            status: status.FAILURE,
            data: action.error,
          },
        };
      })

      .addCase(
        getAllOnboardingQuestions.pending.toString(),
        (state, action) => {
          return {
            ...state,
            allOnboardingQuestionsData: {
              status: status.IN_PROGRESS,
            },
          };
        }
      )
      .addCase(
        getAllOnboardingQuestions.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            allOnboardingQuestionsData: {
              status: status.SUCCESS,
              data: payload.data,
            },
          };
        }
      )
      .addCase(
        getAllOnboardingQuestions.rejected.toString(),
        (state, action) => {
          return {
            ...state,
            allOnboardingQuestionsData: {
              status: status.FAILURE,
              data: action.error,
            },
          };
        }
      )

      .addCase(
        getOnboardingQuestionAnswers.pending.toString(),
        (state, action) => {
          return {
            ...state,
            onBoardingQuestionAnswers: {
              status: status.IN_PROGRESS,
            },
          };
        }
      )
      .addCase(
        getOnboardingQuestionAnswers.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            onBoardingQuestionAnswers: {
              status: status.SUCCESS,
              data: payload.data,
            },
          };
        }
      )
      .addCase(
        getOnboardingQuestionAnswers.rejected.toString(),
        (state, action) => {
          return {
            ...state,
            onBoardingQuestionAnswers: {
              status: status.FAILURE,
              data: action.error,
            },
          };
        }
      );
  },
});

export default FeedBackSlice.reducer;
