import React, { Component } from "react";
import { ValidationEngine, alert, commonFunctions } from "_utilities";
import LoadingButton from "@mui/lab/LoadingButton";
import { addUser } from "Redux-Store/Profile/ProfileThunk";
import { connect } from "react-redux";
import { status } from "_constants";
import { Loading } from "_components";
import "../../_assets/scss/login.scss";
import { CustomTextbox } from "_components";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import LockIcon from "@mui/icons-material/Lock";
import { withRouter } from "react-router-dom";

const styles = {
  button: {
    color: "#434bdf",
    fontSize: "15px",
    fontWeight: "500",
    textTransform: "uppercase",
    background: "#ffffff",
    borderRadius: "7px",
    width: "100%",
    height: "44px",
    textAlign: "center",
    borderColor: "#ffffff",
    marginTop: "9px",
    textTransform: "none",
  },
};

const validationSchema = {
  name: [
    {
      message: "Please enter  Name",
      type: ValidationEngine.type.MANDATORY,
    },
  ],
  email: [
    {
      message: "Please enter email address!",
      type: ValidationEngine.type.MANDATORY,
    },
    {
      message: "Please enter a valid email!",
      type: ValidationEngine.EMAIL_REGEX,
    },
  ],
  password: [
    {
      message: "Please enter password!",
      type: ValidationEngine.type.MANDATORY,
    },
    {
      message: "Please enter a valid password!",
      type: ValidationEngine.PASSWORD_REGEX,
    },
  ],
};

class AddMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      isSubmitted: false,
      showPassword: false,
      showConfirmPassword: false,
      password: "",
      confirmPassword: "",
    };
    this.userData = commonFunctions.getUserData();
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.addUserRes.status !== this.props.addUserRes.status &&
      this.props.addUserRes.status === status.SUCCESS
    ) {
      alert.success("User added successfully!");
      this.setState({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        isSubmitted: false,
      });
      this.props.history.push(`/postlogin/team-members`);
    } else if (
      prevProps.addUserRes.status !== this.props.addUserRes.status &&
      this.props.addUserRes.status === status.FAILURE
    ) {
      alert.error(this.props.addUserRes.data);
    }
  };

  validateForm = () => {
    const { name, email, password, confirmPassword } = this.state;
    const errors = ValidationEngine.validate(validationSchema, {
      name,
      email,
      password,
    });

    if (password !== confirmPassword) {
      errors.isValid = false;
      errors.confirmPassword = {
        isValid: false,
        message: "Passwords do not match!",
      };
    } else {
      errors.confirmPassword = { isValid: true, message: "" };
      errors.isValid = true;
    }

    return errors;
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isSubmitted: true });
    const errors = this.validateForm();
    if (errors.isValid) {
      const { name, email, confirmPassword } = this.state;
      this.props.addUser({ name, email, password: confirmPassword });
    }
  };

  render() {
    const {
      name,
      email,
      password,
      confirmPassword,
      showPassword,
      showConfirmPassword,
      isSubmitted,
    } = this.state;
    const errorData = this.validateForm();
    let { userProfileData, addUserRes } = this.props;
    return (
      <div
        className="d-block w-100 login-main"
        style={{ marginTop: "-85px", height: "calc(100% + 85px)" }}
      >
        <div className="profile-container d-flex align-items-center justify-content-center h-100">
          <div className="d-block w-100 text-center">
            {userProfileData.status === status.IN_PROGRESS ? (
              <div
                className="w-100 text-center d-flex align-items-center justify-content-center"
                style={{ height: "83vh" }}
              >
                <Loading />
              </div>
            ) : (
              <div className="d-inline-block text-left signin-form">
                <form className="w-100" onSubmit={this.handleSubmit}>
                  <CustomTextbox
                    type={"text"}
                    containerClass="custom-input-container position-relative"
                    inputClass="form-control"
                    label={"Name"}
                    htmlFor="name"
                    id="name"
                    name="name"
                    value={name}
                    icon={<PersonIcon />}
                    onChange={this.handleChange}
                    isValid={isSubmitted && errorData.name.isValid}
                    message={isSubmitted && errorData.name.message}
                  />

                  <CustomTextbox
                    type={"email"}
                    containerClass="custom-input-container position-relative"
                    inputClass="form-control"
                    label={"Email"}
                    htmlFor="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                    icon={<EmailIcon />}
                  />
                  <div className="d-block pasword-form">
                    <CustomTextbox
                      type={showPassword ? "text" : "password"}
                      containerClass="custom-input-container position-relative"
                      inputClass="form-control"
                      label={"Password"}
                      htmlFor="password"
                      id="password"
                      name="password"
                      value={password}
                      onChange={this.handleChange}
                      icon={<LockIcon />}
                      isValid={isSubmitted && errorData.password.isValid}
                      message={isSubmitted && errorData.password.message}
                    />
                    <div className="eye-icon">
                      {showPassword ? (
                        <RemoveRedEyeIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            this.setState({ showPassword: !showPassword });
                          }}
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            this.setState({ showPassword: !showPassword });
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="d-block pasword-form">
                    <CustomTextbox
                      type={showConfirmPassword ? "text" : "password"}
                      containerClass="custom-input-container position-relative"
                      inputClass="form-control"
                      label={"Confirm Password"}
                      htmlFor="confirmPassword"
                      id="confirmPassword"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={this.handleChange}
                      icon={<LockIcon />}
                      isValid={isSubmitted && errorData.confirmPassword.isValid}
                      message={isSubmitted && errorData.confirmPassword.message}
                    />
                    <div className="eye-icon">
                      {showConfirmPassword ? (
                        <RemoveRedEyeIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            this.setState({
                              showConfirmPassword: !showConfirmPassword,
                            });
                          }}
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            this.setState({
                              showConfirmPassword: !showConfirmPassword,
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    id="bottom-wizard"
                    className="d-flex align-item-center justify-content-start "
                  >
                    <LoadingButton
                      className="dark-contained-btn"
                      variant="contained"
                      size="medium"
                      loading={addUserRes.status === status.IN_PROGRESS}
                      disabled={addUserRes.status === status.IN_PROGRESS}
                      type="submit"
                      onClick={this.handleSubmit}
                      style={styles.button}
                    >
                      {addUserRes.status !== status.IN_PROGRESS && "Add"}
                      {addUserRes.status === status.IN_PROGRESS && <></>}
                    </LoadingButton>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { userProfileData, addUserRes } = state.profile;
  return {
    userProfileData,
    addUserRes,
  };
}

const mapDispatchToProps = {
  addUser,
};

const connectedProfile = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddMembers);

export default connectedProfile;
