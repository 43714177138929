const dev = {
  respondentPortalUrl:"http://localhost:3001",
  apiUrl: "http://localhost:4002",
  adminUrl: "http://localhost:5001/api/admin",
};

const prod = {
  respondentPortalUrl:"https://respondent.survhana.com",
  apiUrl: "https://business-be.survhana.com",
  adminUrl: "http://admin.survhana.com/api/admin",
};

const test = {
  respondentPortalUrl:"http://localhost:3001",
  apiUrl: "http://localhost:4002",
  adminUrl: "http://localhost:5001/api/admin",
};

const getEnv = () => {
  switch (process.env.REACT_APP_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export default {
  basePath: "/",
  apiUrl: getEnv().apiUrl,
  adminUrl: getEnv().adminUrl,
  respondentPortalUrl: getEnv().respondentPortalUrl,
  printPage:
    process.env.NODE_ENV === "development" ? window.location.origin : "",
  enableGA: true,
  shareSurveyUrl: window.location.origin,
};
