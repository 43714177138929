import { createSlice } from "@reduxjs/toolkit";
import { handleChangePassword } from "./ChangePasswordThunk";
import { status } from "_constants";

const ProfileSlice = createSlice({
  name: "changePassword",
  initialState: {
    changePasswordData: {
      status: null,
      data: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(handleChangePassword.pending.toString(), (state, action) => {
        return {
          ...state,
          changePasswordData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(
        handleChangePassword.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            changePasswordData: {
              status: status.SUCCESS,
              data: payload,
            },
          };
        }
      )
      .addCase(handleChangePassword.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          changePasswordData: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      });
  },
});

export default ProfileSlice.reducer;
