import { createAsyncThunk } from "@reduxjs/toolkit";
import { preLoginService } from "Services";

export const signUp = createAsyncThunk("auth/signUp", async (params) => {
  try {
    const url = `/organization/auth/signup`;
    const response = await preLoginService.post(url, params);
    return response;
  } catch (error) {
    throw error.response?.data;
  }
});

export const handleLogin = createAsyncThunk(
  "auth/handleLogin",
  async (data) => {
    const url = `/organization/auth/login`;
    try {
      const response = await preLoginService.post(url, data);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const checkEmail = createAsyncThunk("auth/checkEmail", async (data) => {
  const url = `/organization/auth/checkemail`;
  try {
    const response = await preLoginService.post(url, data);
    return response;
  } catch (error) {
    throw error.response?.data;
  }
});

export const checkCompany = createAsyncThunk(
  "auth/checkCompany",
  async (data) => {
    const url = `/organization/auth/checkorganization`;
    try {
      const response = await preLoginService.post(url, data);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);
