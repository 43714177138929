export const commonFunctions = {
  convertDateToString,
  validateEmail,
  validateNumeric,
  getJsonFromUrl,

  getAccessToken,
  getFileName,
  getUserData,
  setUserData,
};

const LOCAL_STORAGE_CONSTANTS = {
  CURRENT_USER: "userData",
};

function getAccessToken() {
  const currentUser = getUserData();
  const accessToken = currentUser ? currentUser.token : null;
  return accessToken;
}

function convertDateToString(dateObj) {
  if (dateObj) {
    let month = (dateObj.getMonth() + 1).toString();
    month = month.length === 1 ? "0" + month : month;
    let date = dateObj.getDate().toString();
    date = date.length === 1 ? "0" + date : date;
    let year = dateObj.getFullYear().toString();
    return `${year}-${month}-${date}`;
  }
  return "";
}

function validateEmail(email) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function validateNumeric(number) {
  return /^\d+$/.test(number);
}

function getJsonFromUrl(url) {
  var result = {};
  if (url) {
    var query = url.substr(1);
    query.split("&").forEach(function (part) {
      var item = part.split("=");
      result[item[0]] = decodeURIComponent(item[1]);
    });
  }
  return result;
}

function getFileName(header, type) {
  let fileName = "downloaded." + type;
  if (header) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(header);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, "");
    }
  }
  return fileName;
}

function getUserData() {
  return JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_CONSTANTS.CURRENT_USER) || null
  );
}

function setUserData(data) {
  if (data) {
    let localData = JSON.stringify(data);
    localStorage.setItem(LOCAL_STORAGE_CONSTANTS.CURRENT_USER, localData);
  }
}

export const ValidationEngine = {
  EMAIL_REGEX: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  PASSWORD_REGEX:
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,16}$/,
  MOBILE_NUMBER_REGEX: /^\d{10}$/,
  US_MOBILE_NUMBER_REGEX:
    /^[\+]?[0-9]{0,3}\W?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
  NUMBER_ONLY_REGEX: /^[0-9]+$/,
  DECIMAL_ALLOW_REGEX: /^\d*\.?\d*$/,
  ONLY_TWO_DECIMAL_ALLOW_REGEX: /^(?:\d*\.\d{1,2}|\d+)$/,
  type: {
    MANDATORY: 0,
    REGEX: 1,
    CHARACTERCOUNT: 2,
    OPTIONAL: 3, //This is to be used when item is not mandatory but other validation is added if entered.
  },
  validate: (validationJSON, data) => {
    function validateMandatory(validationObject, value) {
      if (!value || (value && !value.trim())) {
        return {
          isValid: false,
          error: {
            isValid: false,
            message: validationObject.message,
          },
        };
      }
      return {
        isValid: true,
        error: {
          isValid: true,
          message: "",
        },
      };
    }

    function validateCharacterCount(validationObject, value) {
      if (value && value.length !== validationObject.CharacterCout) {
        return {
          isValid: false,
          error: {
            isValid: false,
            message: validationObject.message,
          },
        };
      }
      return {
        isValid: true,
        error: {
          isValid: true,
          message: "",
        },
      };
    }

    function validateRegex(validationObject, value) {
      if (validationObject.regex && !validationObject.regex.test(value)) {
        return {
          isValid: false,
          error: {
            isValid: false,
            message: validationObject.message,
          },
        };
      }
      return {
        isValid: true,
        error: {
          isValid: true,
          message: "",
        },
      };
    }

    const keys = Object.keys(validationJSON);
    const errors = {};
    let isValid = true;
    let errorObj;
    keys.forEach((key) => {
      const validationArray = validationJSON[key];
      const value = data[key];
      for (const validationObj of validationArray) {
        if (validationObj.type === ValidationEngine.type.MANDATORY) {
          errorObj = validateMandatory(validationObj, value);
        } else if (
          validationObj.type === ValidationEngine.type.OPTIONAL &&
          !value
        ) {
          errorObj = {
            isValid: true,
            error: {
              isValid: true,
              message: "",
            },
          };
        } else if (
          validationObj.type === ValidationEngine.type.CHARACTERCOUNT
        ) {
          errorObj = validateCharacterCount(validationObj, value);
        } else if (
          validationObj.type === ValidationEngine.type.REGEX &&
          value
        ) {
          errorObj = validateRegex(validationObj, value);
        }
        errors[key] = errorObj.error;
        isValid = isValid && errorObj.isValid;
        if (!errorObj.isValid) {
          break;
        }
      }
    });
    errors.isValid = isValid;
    return errors;
  },
};
