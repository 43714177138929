import { createSlice } from "@reduxjs/toolkit";
import { getAllMembers, changeOrgMemberStatus } from "./TeamMembersThunk";
import { status } from "_constants";

const TeamMembersSlice = createSlice({
  name: "teamMembers",
  initialState: {
    getAllMembersRes: {
      status: null,
      data: {},
    },
    changeOrgMemberStatusRes: {
      status: null,
      data: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMembers.pending.toString(), (state, action) => {
        return {
          ...state,
          getAllMembersRes: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(getAllMembers.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          getAllMembersRes: {
            status: status.SUCCESS,
            data: payload.data,
          },
        };
      })
      .addCase(getAllMembers.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          getAllMembersRes: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(changeOrgMemberStatus.pending.toString(), (state, action) => {
        return {
          ...state,
          changeOrgMemberStatusRes: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(
        changeOrgMemberStatus.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            changeOrgMemberStatusRes: {
              status: status.SUCCESS,
              data: payload.data,
            },
          };
        }
      )
      .addCase(
        changeOrgMemberStatus.rejected.toString(),
        (state, { error }) => {
          return {
            ...state,
            changeOrgMemberStatusRes: {
              status: status.FAILURE,
              data: error?.message,
            },
          };
        }
      );
  },
});

export default TeamMembersSlice.reducer;
