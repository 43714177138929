import { createAsyncThunk } from "@reduxjs/toolkit";
import { preLoginService, postLoginService } from "Services";

export const getSummaryBySurveyId = createAsyncThunk(
  "summary/getSummaryBySurveyId",
  async (params) => {
    try {
      const url = `/organization/summary/individual/${params}`;
      const response = await postLoginService.get(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getSummaryPDF = createAsyncThunk(
  "summary/getSummaryPDF",
  async (params) => {
    try {
      const url = `/organization/summary/printsummary`;
      const response = await postLoginService.post(url, params, {
        responseType: "blob",
      });
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);
