import React, { Component } from "react";
import { Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { connect } from "react-redux";
import { alert, commonFunctions, ValidationEngine } from "_utilities";
import { CustomTextbox } from "_components";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import LockIcon from "@mui/icons-material/Lock";
import "../../_assets/scss/login.scss";
import { status } from "_constants";
import { handleChangePassword } from "Redux-Store/ChangePassword/ChangePasswordThunk";

const styles = {
  button: {
    color: "#434bdf",
    fontSize: "15px",
    fontWeight: "500",
    textTransform: "uppercase",
    background: "#ffffff",
    borderRadius: "7px",
    width: "100%",
    height: "44px",
    textAlign: "center",
    borderColor: "#ffffff",
    marginTop: "9px",
    textTransform: "none",
  },
};

const validationSchema = {
  password: [
    {
      message: "Please enter Password",
      type: ValidationEngine.type.MANDATORY,
    },
  ],
  confirmPassword: [
    {
      message: "Please enter valid confirm password",
      type: ValidationEngine.type.MANDATORY,
    },
  ],
};

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showConfirmPassword: false,
      password: "",
      confirmPassword: "",
      isSubmitted: false,
    };
    this.userData = commonFunctions.getUserData();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.changePasswordData.status !==
      prevProps.changePasswordData.status
    ) {
      if (this.props.changePasswordData.status === status.SUCCESS) {
        let response = this.props.changePasswordData.data || {};
        if (response?.status) {
          this.props.history.push("/postlogin/dashboard");
          alert.success(response?.message);
        } else {
          alert.error(response?.message);
        }
      } else if (this.props.changePasswordData.status === status.FAILURE) {
        alert.error(
          this.props.changePasswordData?.data === "Rejected"
            ? "There is some internal server error."
            : this.props.changePasswordData?.data
        );
      }
    }
  };

  validateForm = () => {
    const { password, confirmPassword } = this.state;
    const error = ValidationEngine.validate(validationSchema, {
      password,
      confirmPassword: confirmPassword === password ? confirmPassword : "",
    });
    return error;
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState(
      {
        isSubmitted: true,
      },
      () => {
        const errors = this.validateForm();
        if (errors.isValid) {
          const { password } = this.state;

          this.props.handleChangePassword({
            password,
            organizationUserId: this.userData?.userDetails?._id,
          });
        }
      }
    );
  };

  render() {
    const {
      password,
      confirmPassword,
      isSubmitted,
      showPassword,
      showConfirmPassword,
    } = this.state;
    const errorData = this.validateForm();
    let { changePasswordData } = this.props;
    return (
      <div
        className="d-block w-100 login-main"
        style={{ marginTop: "-85px", height: "calc(100% + 85px)" }}
      >
        <div className="d-flex align-items-center justify-content-center h-100">
          <div className="d-block w-100 text-center">
            <div className="d-inline-block text-left signin-form">
              <form className="w-100" onSubmit={this.handleSubmit}>
                <div className="d-block pasword-form">
                  <CustomTextbox
                    type={showPassword ? "text" : "password"}
                    containerClass="custom-input-container position-relative"
                    inputClass="form-control"
                    label={"Password"}
                    htmlFor="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={this.handleChange}
                    icon={<LockIcon />}
                    isValid={isSubmitted && errorData.password.isValid}
                    message={isSubmitted && errorData.password.message}
                  />
                  <div className="eye-icon">
                    {showPassword ? (
                      <RemoveRedEyeIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setState({ showPassword: !showPassword });
                        }}
                      />
                    ) : (
                      <VisibilityOffIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setState({ showPassword: !showPassword });
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="d-block pasword-form">
                  <CustomTextbox
                    type={showConfirmPassword ? "text" : "password"}
                    containerClass="custom-input-container position-relative"
                    inputClass="form-control"
                    label={"Confirm Password"}
                    htmlFor="confirmPassword"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={this.handleChange}
                    icon={<LockIcon />}
                    isValid={isSubmitted && errorData.confirmPassword.isValid}
                    message={isSubmitted && errorData.confirmPassword.message}
                  />
                  <div className="eye-icon">
                    {showConfirmPassword ? (
                      <RemoveRedEyeIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setState({
                            showConfirmPassword: !showConfirmPassword,
                          });
                        }}
                      />
                    ) : (
                      <VisibilityOffIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setState({
                            showConfirmPassword: !showConfirmPassword,
                          });
                        }}
                      />
                    )}
                  </div>
                </div>

                <div
                  id="bottom-wizard"
                  className="d-flex align-item-center justify-content-start "
                >
                  <LoadingButton
                    className="dark-contained-btn"
                    variant="contained"
                    size="medium"
                    loading={changePasswordData.status === status.IN_PROGRESS}
                    disabled={changePasswordData.status === status.IN_PROGRESS}
                    type="submit"
                    style={styles.button}
                    onClick={this.handleSubmit}
                  >
                    {changePasswordData.status !== status.IN_PROGRESS &&
                      "Change Password"}
                    {changePasswordData.status === status.IN_PROGRESS && <></>}
                  </LoadingButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { changePasswordData } = state.changePassword;
  return { changePasswordData };
}

const mapDispatchToProps = {
  handleChangePassword,
};

const connectedChangePassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);

export default connectedChangePassword;
