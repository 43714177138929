import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "Redux-Store/Auth/AuthSlice";
import SurveysSlice from "Redux-Store/Questionnaires/QuestionnairesSlice";
import FeedBackSlice from "Redux-Store/FeedBack/FeedBackSlice";
import SummarySlice from "Redux-Store/Summary/SummarySlice";
import CriteriaSlice from "Redux-Store/Criteria/CriteriaSlice";
import ProfileSlice from "./Profile/ProfileSlice";
import ChangePasswordSlice from "./ChangePassword/ChangePasswordSlice";
import TeamMembersSlice from "./TeamMembers/TeamMembersSlice";

const store = configureStore({
  reducer: {
    auth: AuthSlice,
    surveys: SurveysSlice,
    feedback: FeedBackSlice,
    summary: SummarySlice,
    criteria: CriteriaSlice,
    profile: ProfileSlice,
    changePassword: ChangePasswordSlice,
    teamMembers: TeamMembersSlice,
  },
});

export default store;
