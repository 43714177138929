import ChangePassword from "PostLogin/ChangePassword";
import Profile from "PostLogin/Profile";
import React from "react";
import AddMembers from "PostLogin/AddMembers";

const Dashboard = React.lazy(() => import("../PostLogin/Dashboard"));
const AddQuestionnaires = React.lazy(() =>
  import("../PostLogin/AddQuestionnaires")
);
const QuestionnaireList = React.lazy(() =>
  import("../PostLogin/QuestionnaireList")
);
const ViewQuestionnaire = React.lazy(() =>
  import("../PostLogin/AddQuestionnaires/ViewQuestionnaire")
);
const Answers = React.lazy(() => import("../PostLogin/Answers"));
const Summary = React.lazy(() => import("../PostLogin/Summary"));
const PrintSummary = React.lazy(() =>
  import("../PostLogin/Summary/PrintSummary")
);
const SetCriteria = React.lazy(() =>
  import("../PostLogin/AddQuestionnaires/SetCriteria")
);
const TeamMembers = React.lazy(() => import("../PostLogin/TeamMembers"));

const routes = [
  {
    path: "/postlogin/dashboard",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/postlogin/surveys/add/:id?",
    exact: true,
    name: "AddQuestionnaires",
    component: AddQuestionnaires,
  },
  {
    path: "/postlogin/surveys",
    exact: true,
    name: "QuestionnaireList",
    component: QuestionnaireList,
  },
  {
    path: "/survey/:id",
    exact: true,
    name: "ViewQuestionnaire",
    component: ViewQuestionnaire,
  },
  {
    path: "/preview/:id",
    exact: true,
    name: "PreviewQuestionnaire",
    component: ViewQuestionnaire,
  },
  {
    path: "/postlogin/answers/:surveyId",
    exact: true,
    name: "Answers",
    component: Answers,
  },
  {
    path: "/postlogin/summary/:surveyId",
    exact: true,
    name: "Summary",
    component: Summary,
  },
  {
    path: "/postlogin/printsummary/:surveyId",
    exact: true,
    name: "Print Summary",
    component: PrintSummary,
  },
  {
    path: "/postlogin/criteria/:id",
    exact: true,
    name: "SetCriteria",
    component: SetCriteria,
  },
  {
    path: "/postlogin/profile",
    exact: true,
    name: "profile",
    component: Profile,
  },
  {
    path: "/postlogin/add-members",
    exact: true,
    name: "AddMembers",
    component: AddMembers,
  },
  {
    path: "/postlogin/team-members",
    exact: true,
    name: "TeamMembers",
    component: TeamMembers,
  },

  {
    path: "/postlogin/change-password",
    exact: true,
    name: "ChangePassword",
    component: ChangePassword,
  },
];

export default routes;
