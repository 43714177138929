import { createAsyncThunk } from "@reduxjs/toolkit";
import { preLoginService, postLoginService } from "Services";

export const createFeedback = createAsyncThunk(
  "feedback/createFeedback",
  async (params) => {
    try {
      const url = `/organization/feedback`;
      const response = await postLoginService.post(url, params);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getResponseOfQuestionnaire = createAsyncThunk(
  "feedback/getResponseOfQuestionnaire",
  async (params) => {
    try {
      const url = `/organization/feedback/getall/${params}`;
      const response = await postLoginService.get(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getDownloadFile = createAsyncThunk(
  "feedback/getDownloadFile",
  async (params) => {
    try {
      const url = `/organization/feedback/generate-report/${params.surveyId}`;
      const response = await postLoginService.post(url, params, {
        responseType: "blob",
      });
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const outroCallBackApi = createAsyncThunk(
  "feedback/outroCallBackApi",
  async (params) => {
    try {
      let { url, data } = params;

      const response = await postLoginService.post(url, { data });
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getAllOnboardingQuestions = createAsyncThunk(
  "feedback/getAllOnboardingQuestions",
  async (surveyId) => {
    try {
      const url = `organization/onboardingQuestions/getallonboardingquestions`;
      const response = await postLoginService.get(url, { surveyId });
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getOnboardingQuestionAnswers = createAsyncThunk(
  "feedback/getOnboardingQuestionAnswers",
  async (respondentId) => {
    try {
      const url = `organization/onboardingQuestions/onboardingquestionanswers`;
      const response = await postLoginService.post(url, { respondentId });
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);
