import { createSlice } from "@reduxjs/toolkit";
import {
  getSummaryBySurveyId,
  getSummaryPDF,
} from "Redux-Store/Summary/SummaryThunk";
import { status } from "../../_constants";

const SummarySlice = createSlice({
  name: "summary",
  initialState: {
    summaryBySurveyIdData: {
      status: null,
      data: {},
    },
    printSummaryData: {
      status: null,
      data: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSummaryBySurveyId.pending.toString(), (state, action) => {
        return {
          ...state,
          summaryBySurveyIdData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(
        getSummaryBySurveyId.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            summaryBySurveyIdData: {
              status: status.SUCCESS,
              data: payload.data,
            },
          };
        }
      )
      .addCase(getSummaryBySurveyId.rejected.toString(), (state, {error}) => {
        return {
          ...state,
          summaryBySurveyIdData: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(getSummaryPDF.pending.toString(), (state, action) => {
        return {
          ...state,
          printSummaryData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(getSummaryPDF.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          printSummaryData: {
            status: status.SUCCESS,
            data: payload,
          },
        };
      })
      .addCase(getSummaryPDF.rejected.toString(), (state, action) => {
        return {
          ...state,
          printSummaryData: {
            status: status.FAILURE,
            data: action.error,
          },
        };
      });
  },
});

export default SummarySlice.reducer;
