import React, { Component } from "react";

export class CustomTextbox extends Component {
  onChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };
  render() {
    const {
      containerClass,
      labelClass,
      inputClass,
      label,
      htmlFor,
      id,
      name,
      placeholder,
      value,
      icon,
      isValid,
      message,
      type,
      disabled,
    } = this.props;
    return (
      <div className={containerClass}>
        <label className={labelClass} htmlFor={htmlFor}>
          {label}
        </label>
        {icon}
        <input
          type={type ? type : "text"}
          className={`${inputClass} ${isValid ? "" : "is-invalid"}`}
          id={id}
          name={name}
          value={value}
          onChange={this.onChange}
          disabled={disabled}
        />
        {!isValid && <div class="invalid-feedback">{message}</div>}
      </div>
    );
  }
}
