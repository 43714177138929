import { createSlice } from "@reduxjs/toolkit";
import {
  getCriteriaQuestions,
  saveCriteriaQuestions,
  getCriteriaQuestionById,
} from "Redux-Store/Criteria/CriteriaThunk";
import { status } from "../../_constants";

const CriteriaSlice = createSlice({
  name: "criteria",
  initialState: {
    criteriaQuestions: {
      status: null,
      data: [],
    },
    saveQuestions: {
      status: null,
      data: {},
    },
    criteriaAnswersData: {
      status: null,
      data: [],
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCriteriaQuestions.pending.toString(), (state, action) => {
        return {
          ...state,
          criteriaQuestions: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(
        getCriteriaQuestions.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            criteriaQuestions: {
              status: status.SUCCESS,
              data: payload.data,
            },
          };
        }
      )
      .addCase(getCriteriaQuestions.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          criteriaQuestions: {
            status: status.FAILURE,
            data: error.message,
          },
        };
      })
      .addCase(saveCriteriaQuestions.pending.toString(), (state, action) => {
        return {
          ...state,
          saveQuestions: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(
        saveCriteriaQuestions.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            saveQuestions: {
              status: status.SUCCESS,
              data: payload,
            },
          };
        }
      )
      .addCase(
        saveCriteriaQuestions.rejected.toString(),
        (state, { error }) => {
          return {
            ...state,
            saveQuestions: {
              status: status.FAILURE,
              data: error?.message,
            },
          };
        }
      )

      .addCase(getCriteriaQuestionById.pending.toString(), (state, action) => {
        return {
          ...state,
          criteriaAnswersData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(
        getCriteriaQuestionById.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            criteriaAnswersData: {
              status: status.SUCCESS,
              data: {
                answers: payload?.data || {},
                surveys: payload?.surveyData || {},
              },
            },
          };
        }
      )
      .addCase(getCriteriaQuestionById.rejected.toString(), (state, action) => {
        return {
          ...state,
          criteriaAnswersData: {
            status: status.FAILURE,
            data: action.error,
          },
        };
      });
  },
});

export default CriteriaSlice.reducer;
