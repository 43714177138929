import React, { Component } from "react";
import "../../_assets/scss/respondentsTearmsAndConditons.scss";

class RespondentsTearmsAndConditons extends Component {
  render() {
    return (
      <div className="termsAndConditions-container">
        <div className="termsAndConditions-inner-container">
          <p>Effective Date: September 30, 2024</p>
          <p>
            Welcome to the SurvHaNa Organization Portal ("Portal"). By
            registering for and using this Portal to create surveys, target
            audiences, and view survey responses, you ("Organization") agree to
            abide by these Terms and Conditions ("Terms"). If you do not agree
            with these Terms, you should not use the Portal.
          </p>
          <h3>1. Definitions</h3>
          <ul>
            <li>
              <strong>SurvHaNa &#8282;</strong> Refers to the SurvHaNa platform,
              a software solution for organizations to create and publish
              targeted surveys.
            </li>
            <li>
              <strong>Organization &#8282;</strong> Any business, institution,
              or entity that uses the Portal to create surveys and collect data.
            </li>
            <li>
              <strong>Respondent &#8282;</strong> An individual who participates
              in a survey through SurvHaNa’s Respondents Portal.
            </li>
            <li>
              <strong>Portal &#8282;</strong> The web interface provided by
              SurvHaNa where organizations create surveys, view responses, and
              manage their data.
            </li>
          </ul>
          <h3>2. Account Registration</h3>
          <div className="d-block terms-content mb-3">
            <label>2.1 Eligibility :</label>
            <p>
              To use the Portal, you must be a legal entity or authorized
              representative of a legal entity capable of entering into binding
              contracts under applicable law. By creating an account, you
              represent that the information you provide is accurate, complete,
              and up to date.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>2.2 Account Responsibility :</label>
            <p>
              You are responsible for maintaining the confidentiality of your
              account credentials and for all activities that occur under your
              account. SurvHaNa is not liable for any unauthorized access or
              actions taken using your account.
            </p>
          </div>
          <h3>3. Survey Creation and Management</h3>
          <div className="d-block terms-content mb-3">
            <label>3.1 Survey Creation :</label>
            <p>
              As an Organization, you can create and publish surveys on the
              SurvHaNa platform. You are responsible for defining the survey
              content, including the questions, target audience, and criteria
              for respondent eligibility (e.g., demographic factors such as age,
              gender, location).
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>3.2 Compliance with Laws :</label>
            <p>
              You agree that your surveys will comply with all applicable laws
              and regulations, including but not limited to privacy laws, data
              protection regulations, and intellectual property laws. You must
              not publish surveys that:
            </p>
            <ul>
              <li>Contain defamatory, misleading, or inappropriate content.</li>
              <li>
                Violate any third-party rights, including privacy or
                intellectual property rights.
              </li>
              <li>
                Target individuals based on unlawful or discriminatory criteria.
              </li>
            </ul>
            <p>
              SurvHaNa reserves the right to review, modify, or remove any
              surveys that violate these Terms.
            </p>
          </div>

          <h3>4. Survey Responses</h3>
          <div className="d-block terms-content mb-3">
            <label>4.1 Response Accuracy :</label>
            <p>
              SurvHaNa facilitates the collection of responses from eligible
              respondents. However, the Organization acknowledges that SurvHaNa
              cannot guarantee the accuracy, completeness, or honesty of the
              responses submitted by respondents.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>4.2 Data Access and Use :</label>
            <p>
              The Organization is granted access to the survey responses through
              the Portal. This data is provided for analytical purposes only.
              The Organization must not use the data for any unlawful purposes
              or share it with unauthorized third parties.
            </p>
          </div>

          <h3>5. Data Privacy and Security</h3>
          <div className="d-block terms-content mb-3">
            <label>5.1 Data Protection :</label>
            <p>
              SurvHaNa takes the privacy of respondents seriously. The
              Organization agrees to handle all survey data in accordance with
              applicable data protection laws, including but not limited to the
              General Data Protection Regulation (GDPR) and India’s Information
              Technology (IT) Act, where applicable.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>5.2 Use of Personal Data :</label>
            <p>
              The Organization may receive demographic data (such as age,
              gender, income, etc.) about respondents. This data must be used
              solely for the purposes of analyzing survey results. You must not
              attempt to identify individual respondents without their express
              consent, nor use the data for any purpose beyond the survey's
              stated objective.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>5.3 Security Measures :</label>
            <p>
              SurvHaNa implements reasonable security measures to protect the
              data collected through the Portal. However, the Organization
              acknowledges that no data transmission over the internet or
              electronic storage is completely secure. The Organization agrees
              to implement its own data security measures to ensure the
              protection of any downloaded or accessed data.
            </p>
          </div>
          <h3>6. Payments and Fees</h3>
          <div className="d-block terms-content mb-3">
            <label>6.1 Pricing Structure :</label>
            <p>
              SurvHaNa offers its services on a fee-based model. Pricing for
              surveys is based on the number of respondents, questions, and any
              additional features or customizations requested. The details of
              the pricing model are provided at the time of survey creation.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>6.2 Payment Terms :</label>
            <p>
              The Organization agrees to pay all applicable fees as specified
              when creating the survey. Payment is required prior to the survey
              being published unless otherwise agreed in writing.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>6.3 Refunds :</label>
            <p>
              Fees are generally non-refundable. However, if a survey is
              canceled or rejected by SurvHaNa due to a violation of these
              Terms, a partial refund may be considered at SurvHaNa’s
              discretion.
            </p>
          </div>
          <h3>7. Intellectual Property</h3>
          <div className="d-block terms-content mb-3">
            <label>7.1 Ownership of Content :</label>
            <p>
              The Organization retains ownership of any content it uploads or
              submits to the Portal, including survey questions, instructions,
              and custom materials. By submitting this content, the Organization
              grants SurvHaNa a non-exclusive, royalty-free license to use,
              host, reproduce, and display the content as necessary to operate
              the Services.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>7.2 Platform Ownership :</label>
            <p>
              All content and technology associated with the Portal, including
              but not limited to text, images, logos, and software, is owned by
              SurvHaNa or its licensors and is protected by intellectual
              property laws.
            </p>
          </div>
          <h3>8. Termination and Suspension</h3>
          <div className="d-block terms-content mb-3">
            <label>8.1 Termination by Organization :</label>
            <p>
              You may terminate your use of the Portal at any time by deleting
              your account. Upon termination, you will lose access to any active
              surveys and the associated data.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>8.2 Termination by SurvHaNa : </label>
            <p>
              SurvHaNa may terminate or suspend your access to the Portal at any
              time, with or without notice, if you violate these Terms, engage
              in fraudulent activities, or fail to pay required fees.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>8.3 Effect of Termination : </label>
            <p>
              Upon termination, any outstanding payments owed by the
              Organization will become immediately due. Additionally, SurvHaNa
              may retain certain data as required by law or to fulfill ongoing
              legal obligations.
            </p>
          </div>
          <h3>9. Limitation of Liability</h3>
          <div className="d-block terms-content mb-3">
            <label>9.1 Disclaimer of Warranties :</label>
            <p>
              The Portal and Services are provided on an "as-is" and
              "as-available" basis. SurvHaNa makes no warranties or
              representations regarding the accuracy or reliability of the
              Portal or its ability to meet your specific needs.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>9.2 Limitation of Liability :</label>
            <p>
              To the fullest extent permitted by law, SurvHaNa shall not be
              liable for any indirect, incidental, special, or consequential
              damages arising from your use of the Portal, including but not
              limited to lost profits or business interruption.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>9.3 Maximum Liability :</label>
            <p>
              SurvHaNa’s total liability to the Organization for any claims
              arising out of or related to the Portal shall not exceed the total
              amount paid by the Organization for the relevant survey services
            </p>
          </div>
          <h3>10. Governing Law and Dispute Resolution</h3>
          <div className="d-block terms-content mb-3">
            <label>10.1 Governing Law :</label>
            <p>
              These Terms are governed by and construed in accordance with the
              laws of India, without regard to its conflict of law principles.
            </p>
          </div>
          <div className="d-block terms-content mb-3">
            <label>10.2 Dispute Resolution :</label>
            <p>
              Any disputes arising from or relating to these Terms will be
              resolved through binding arbitration under the [applicable
              arbitration rules] of India. You agree to submit to the
              jurisdiction of the courts located in [Insert Location].
            </p>
          </div>
          <h3>11. Changes to These Terms</h3>
          <p>
            SurvHaNa reserves the right to modify these Terms at any time. We
            will notify the Organization of any changes by posting the updated
            Terms on the Portal. Your continued use of the Portal after the
            changes take effect constitutes your acceptance of the revised
            Terms.
          </p>
         
          <h3>12. Contact Information</h3>
          <p>
          If you have any questions or concerns about these Terms, please contact us at :
          </p>
          <ul className="border-bottom pb-4">
            <li>
              <span> Email : </span>
              <a href="mailto : contact@survhana.com">contact@survhana.com</a>
            </li>
            <li>
              <span>Website : </span>
              <a href="https://www.survhana.com/organization">
                www.survhana.com{" "}
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default RespondentsTearmsAndConditons;
