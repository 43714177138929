import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import routes from "../_routes/loginRoutes";
import { withTranslation } from "react-i18next";
import logoLogin from "../_assets/images/logo-login.png";
import "../_assets/scss/login-layout.scss";
import loginImg from "../_assets/images/login-img.gif";
import { URLS } from "_constants";

class LoginLayout extends Component {
  createRoutes = () => {
    const retData = routes.map((route, index) => {
      return route.component ? (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          name={route.name}
          render={(props) => <route.component {...props} />}
        />
      ) : null;
    });
    return [
      ...retData,
      <Route
        path="*"
        render={() => {
          return <Redirect to="/prelogin/login" />;
        }}
      />,
    ];
  };

  onClickRegister = () => {
    const { history } = this.props;
    history.push("/prelogin/register");
  };

  onClickLogin = () => {
    const { history } = this.props;
    history.push("/prelogin/login");
  };

  render() {
    return (
      <div className="d-flex w-100 login-main-container">
        <div className="d-inline-flex w-50 h-100 login-main-left">
          <div className="d-flex w-100 logo">
            <a href={URLS.HOME} target="_blank">
              <img src={logoLogin} />
            </a>
          </div>
          <div className="d-flex w-100 banner-img">
            <img src={loginImg} alt="" />
          </div>
          <div className="d-flex w-100 bottom-links">
            <ul className="d-inline-flex w-75 link-block">
              <li>
                <a href={URLS.HOME} target="_blank">
                  Home
                </a>
              </li>
              <li>
                <a href={URLS.CONTACT_US} target="_blank">
                  Contact us
                </a>
              </li>
              <li>
                <a href={URLS.PRIVACY_POLICY} target="_blank">
                  Privacy policy
                </a>
              </li>
            </ul>
            <div className="d-inline-flex w-25 text-right copy-right-text">
              &copy; SurvHaNa 2024
            </div>
          </div>
        </div>
        <div className="d-inline-flex w-50 login-component-container">
          <Switch>{this.createRoutes()}</Switch>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

const connectedLoginLayout = withTranslation()(
  connect(mapStateToProps)(LoginLayout)
);

export { connectedLoginLayout as LoginLayout };
