import { withStyles } from "@mui/styles";
import Slider from "@mui/material/Slider";

export const CustomSlider = withStyles({
  root: {
    color: "#5656B7",
    height: 5,
  },
  thumb: {
    height: 23,
    width: 23,
    marginTop: "-9px",
    marginLeft: "-12px",
    backgroundColor: "#DAE1F8",
    boxShadow: "3px 3px 10px #00000029",
    "&:focus, &:hover, &$active": {
      boxShadow: "3px 3px 10px #00000029",
    },
  },
  active: {},
  track: {
    height: 5,
    borderRadius: 3,
  },
  rail: {
    height: 5,
    borderRadius: 3,
    color: "#3C476C4D",
  },
  valueLabel: {
    left: "-4px",
    fontFamily: "Open Sans",
    fontSize: "13px",
    fontWeight: 600,
    top: -17,
    "& *": {
      background: "transparent",
      color: "#434bdf",
    },
  },
})(Slider);
