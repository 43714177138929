import React from "react";
import { makeStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#434bdf",
  },
  tooltip: {
    backgroundColor: "#434bdf",
    fontFamily: "Open Sans",
    fontWeight: "500",
    color: "#F5F5F5",
    fontSize: 11,
  },
}));

export function CustomTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}
