import ReactGA from "react-ga4";
import config from "../config";
import { GOOGLE_ANALYTICS } from "_constants";
export const GA = {
  dispatchGAEvent,
  initialize,
};

function dispatchGAEvent(category, action, label) {
  if (config.enableGA) {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  }
}

function initialize() {
  if (config.enableGA) {
    ReactGA.initialize(GOOGLE_ANALYTICS.MEASURMENT_ID);
  }
}
