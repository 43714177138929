import { createSlice } from "@reduxjs/toolkit";
import {
  createQuestionnaires,
  getAllQuestionnaire,
  getIndividualQuestionnaire,
  validateSurveyName,
  uploadSurveyImage,
  removeSurveyImage,
  setSurveyLogic,
} from "Redux-Store/Questionnaires/QuestionnairesThunk";
import { status } from "../../_constants";

const QuestionnairesSlice = createSlice({
  name: "questionnaires",
  initialState: {
    createQuestionnairesData: {
      status: null,
      data: {},
    },
    questionnairesData: {
      status: null,
      data: [],
    },
    individualQuestionnaireData: {
      status: null,
      data: [],
    },
    validateSurveyNameData: {
      status: null,
      data: {},
    },
    surveyData: {
      status: null,
      data: {},
    },
    uploadSurveyImageData: {
      status: null,
      data: {},
    },
    removeSurveyImageData: {
      status: null,
      data: {},
    },
    setSurveyLogicData: {
      status: null,
      data: {},
    },
  },
  reducers: {
    setSurveyData: (state, action) => {
      let surveyData = {
        status: null,
        data: action.payload,
      };
      return {
        ...state,
        surveyData,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createQuestionnaires.pending.toString(), (state, action) => {
        return {
          ...state,
          createQuestionnairesData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(
        createQuestionnaires.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            createQuestionnairesData: {
              status: status.SUCCESS,
              data: payload.data,
            },
          };
        }
      )
      .addCase(createQuestionnaires.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          createQuestionnairesData: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(getAllQuestionnaire.pending.toString(), (state, action) => {
        return {
          ...state,
          questionnairesData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(
        getAllQuestionnaire.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            questionnairesData: {
              status: status.SUCCESS,
              data: payload.data,
            },
          };
        }
      )
      .addCase(getAllQuestionnaire.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          questionnairesData: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(
        getIndividualQuestionnaire.pending.toString(),
        (state, action) => {
          return {
            ...state,
            individualQuestionnaireData: {
              status: status.IN_PROGRESS,
            },
          };
        }
      )
      .addCase(
        getIndividualQuestionnaire.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            individualQuestionnaireData: {
              status: status.SUCCESS,
              data: payload.data,
            },
          };
        }
      )
      .addCase(
        getIndividualQuestionnaire.rejected.toString(),
        (state, { error }) => {
          return {
            ...state,
            individualQuestionnaireData: {
              status: status.FAILURE,
              data: error?.message,
            },
          };
        }
      )

      .addCase(validateSurveyName.pending.toString(), (state, action) => {
        return {
          ...state,
          validateSurveyNameData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(
        validateSurveyName.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            validateSurveyNameData: {
              status: status.SUCCESS,
              data: payload.data,
            },
          };
        }
      )
      .addCase(validateSurveyName.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          validateSurveyNameData: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(uploadSurveyImage.pending.toString(), (state, action) => {
        return {
          ...state,
          uploadSurveyImageData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(uploadSurveyImage.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          uploadSurveyImageData: {
            status: status.SUCCESS,
            data: payload.data,
          },
        };
      })
      .addCase(uploadSurveyImage.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          uploadSurveyImageData: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(removeSurveyImage.pending.toString(), (state, action) => {
        return {
          ...state,
          removeSurveyImageData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(removeSurveyImage.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          removeSurveyImageData: {
            status: status.SUCCESS,
            data: payload.data,
          },
        };
      })
      .addCase(removeSurveyImage.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          removeSurveyImageData: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(setSurveyLogic.pending.toString(), (state, action) => {
        return {
          ...state,
          setSurveyLogicData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(setSurveyLogic.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          setSurveyLogicData: {
            status: status.SUCCESS,
            data: payload.data,
          },
        };
      })
      .addCase(setSurveyLogic.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          setSurveyLogicData: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      });
  },
});
export const { setSurveyData } = QuestionnairesSlice.actions;
export default QuestionnairesSlice.reducer;
