import React, { Component } from "react";
import Button from "@mui/material/Button";
import "../../_assets/scss/upload-picture.scss";
import CheckIcon from "@mui/icons-material/Check";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { status } from "../../_constants";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { alert } from "../../_utilities";
import LoadingButton from "@mui/lab/LoadingButton";

const styles = {
  choseButton: {
    color: "#5959BE",
    fontSize: "12px",
    fontFamily: "Open Sans",
    fontWeight: "bold",
    background: "#ffffff",
    borderRadius: "5px",
    width: "155px",
    height: "32px",
    textAlign: "center",
    border: "1px solid #5959BE",
    textTransform: "none",
  },
  confirm: {
    background: "#5959BE",
    color: "#ffffff",
    fontSize: "10px",
    height: "33px",
    width: "100px",
    fontWeight: "500",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#5959BE",
    textTransform: "none",
  },
};

class UploadPicture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gallery: [],
      selectedImage: this.props.selectedImage ? this.props.selectedImage : null,
      activeImageIndex: -1,
      selectedFile: null,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (
      prevProps.get_test_gallary_status !==
        this.props.get_test_gallary_status &&
      this.props.get_test_gallary_status === status.SUCCESS
    ) {
      this.setState({
        gallery: this.props.testGallary,
      });
    }
    if (
      prevProps.upload_gallery_image_status !==
        this.props.upload_gallery_image_status &&
      this.props.upload_gallery_image_status === status.SUCCESS
    ) {
      this.props.onSelectImage(this.props.uploadImageData);
    }
    if (prevProps.selectedImage !== this.props.selectedImage) {
      this.setState({
        selectedImage: this.props.selectedImage,
      });
    }
  }

  onImageSelected = (event) => {
    const { t } = this.props;
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      let file = event.target.files[0];
      reader.onload = (e) => {
        let img = new Image();
        img.onload = () => {
          if (img.width) {
            let width = img.width;
            let height = img.height;
            let ratio = width / height;
            if (ratio <= 1.6 && ratio >= 1.4) {
              this.setState({
                selectedImage: e.target.result,
                activeImageIndex: -1,
                selectedFile: file,
              });
              this.props.onClickImage({ link: e.target.result });
            } else {
              alert.error(
                t(`Image width to height ratio should be between 1.4 to 1.6`)
              );
              this.setState({
                selectedImage: null,
                activeImageIndex: -1,
                selectedFile: null,
              });
            }
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  onClickConfirm = () => {
    const { selectedImage, selectedFile, activeImageIndex } = this.state;
    const { t } = this.props;
    if (selectedFile && activeImageIndex === -1) {
    } else if (selectedImage) {
      this.props.onSelectImage({ link: selectedImage });
    } else {
      alert.error(t("Please select an image"));
    }
  };

  setSelectedImage = (index) => {
    const { gallery } = this.state;
    this.setState({
      activeImageIndex: index,
      selectedImage: gallery[index].link,
    });
    this.props.onClickImage({ link: gallery[index].link });
  };

  renderGallery = () => {
    const { gallery, activeImageIndex } = this.state;
    const retData = [];
    for (let i = 0; i < gallery.length; i++) {
      retData.push(
        <div
          className={`image-container col-12 col-lg-4 ${
            activeImageIndex === i ? "active" : ""
          }`}
          onClick={() => {
            this.setSelectedImage(i);
          }}
        >
          <div className={`d-block image`}>
            <img src={gallery[i].link} alt="" />
          </div>
          <div className="rounded-circle icon">
            <CheckIcon />
          </div>
        </div>
      );
    }
    return retData;
  };

  onClickChooseFromLibrary = () => {
    // this.props.dispatch(testActions.getTestGallery());
  };

  render() {
    const { selectedImage } = this.state;
    const {
      t,
      get_test_gallary_status,
      upload_gallery_image_status,
      create_test_status,
      isLoading,
    } = this.props;
    return (
      <div className="upload-library-container">
        <div className="d-block page-heading">
          <div className="d-flex">
            <h1 className="heading">*</h1>
            <div className="">
              <h1 className="heading">
                {t("Upload a product picture or choose from our library")}
              </h1>
              <p className="notice">
                {t(
                  "Choose a picture that will attract testers to engage with the test"
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="d-block text-center upload-image-container">
          <div className="d-inline-block choose-image-box">
            {selectedImage && (
              <div className="d-block bg-white rounded border upload-library-box">
                <div className="d-block w-100 upload-library">
                  <img src={selectedImage} alt="" />
                </div>
                <div className="d-block w-100">
                  <div className="d-inline-block w-50 upload-new-btn">
                    <input
                      accept="image/*"
                      className="d-none"
                      id="select-file-1"
                      type="file"
                      onChange={this.onImageSelected}
                    />
                    <label htmlFor="select-file-1" className="d-block">
                      <Button
                        variant="contained"
                        className="upload-new"
                        autoCapitalize={false}
                        component="span"
                      >
                        {t("Upload new")}
                      </Button>
                    </label>
                  </div>
                  <div className="d-inline-block w-50">
                    <LoadingButton
                      className=""
                      style={styles.confirm}
                      autoCapitalize={false}
                      onClick={this.onClickConfirm}
                      loading={
                        upload_gallery_image_status === status.IN_PROGRESS ||
                        create_test_status === status.IN_PROGRESS ||
                        isLoading
                      }
                      disabled={
                        upload_gallery_image_status === status.IN_PROGRESS ||
                        create_test_status === status.IN_PROGRESS ||
                        isLoading
                      }
                    >
                      {upload_gallery_image_status !== status.IN_PROGRESS &&
                        create_test_status !== status.IN_PROGRESS &&
                        !isLoading &&
                        t("Confirm")}
                    </LoadingButton>
                  </div>
                </div>
              </div>
            )}
            {!selectedImage && (
              <div className="d-block bg-white rounded border upload-library-box">
                <div className="d-inline-block rounded-circle upload-image">
                  <ArrowUpwardIcon />
                </div>
                <div className="d-block upload-btn">
                  <input
                    accept="image/*"
                    className="d-none"
                    id="select-file-2"
                    type="file"
                    onChange={this.onImageSelected}
                  />
                  <label htmlFor="select-file-2" className="d-block">
                    <Button
                      variant="contained"
                      className="w-100 upload"
                      component="span"
                    >
                      {t("Upload")}
                    </Button>
                  </label>
                </div>
              </div>
            )}
            <div className="d-block choose-library-btn">
              <LoadingButton
                loading={get_test_gallary_status === status.IN_PROGRESS}
                className="w-100"
                style={styles.choseButton}
                onClick={this.onClickChooseFromLibrary}
                disabled={get_test_gallary_status === status.IN_PROGRESS}
              >
                {get_test_gallary_status !== status.IN_PROGRESS &&
                  t("Choose from image library")}
                {get_test_gallary_status === status.IN_PROGRESS && <></>}
              </LoadingButton>
            </div>
          </div>
        </div>
        <div className="d-block w-100 library-images">
          <div className="row">{this.renderGallery()}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    get_test_gallary_status,
    testGallary,
    upload_gallery_image_status,
    uploadImageData,
    create_test_status,
  } = state.test;
  return {
    get_test_gallary_status,
    testGallary,
    upload_gallery_image_status,
    uploadImageData,
    create_test_status,
  };
}

const connectedUploadPicture = withTranslation()(
  connect(mapStateToProps)(UploadPicture)
);
export { connectedUploadPicture as UploadPicture };
