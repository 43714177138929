import { createAsyncThunk } from "@reduxjs/toolkit";
import { postLoginService } from "Services";

export const getCriteriaQuestions = createAsyncThunk(
  "criteria/getCriteriaQuestions",
  async (params) => {
    try {
      const url = `/organization/criteriaquestion`;
      const response = await postLoginService.get(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const saveCriteriaQuestions = createAsyncThunk(
  "criteria/saveCriteriaQuestions",
  async (params) => {
    try {
      const url = `/organization/criteriaAnswer`;
      const response = await postLoginService.post(url, params);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getCriteriaQuestionById = createAsyncThunk(
  "criteria/getCriteriaQuestionById",
  async (surveyId) => {
    try {
      const url =`/organization/criteriaAnswer/getall/${surveyId}`;
      const response = await postLoginService.post(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

