import { createSlice } from "@reduxjs/toolkit";
import {
  signUp,
  handleLogin,
  checkEmail,
  checkCompany,
  registerMember,
} from "Redux-Store/Auth/AuthThunk";
import { status } from "../../_constants";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    registerData: {
      status: null,
      data: {},
    },
    loggedInUser: {
      status: null,
      data: {},
    },
    checkEmailData: {
      status: null,
      data: {},
    },
    checkCompanyData: {
      status: null,
      data: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signUp.pending.toString(), (state, action) => {
        return {
          ...state,
          registerData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(signUp.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          registerData: {
            status: status.SUCCESS,
            data: payload.data,
          },
        };
      })
      .addCase(signUp.rejected.toString(), (state, action) => {
        return {
          ...state,
          registerData: {
            status: status.FAILURE,
            data: action.error,
          },
        };
      })

      .addCase(checkEmail.pending.toString(), (state, action) => {
        return {
          ...state,
          checkEmailData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(checkEmail.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          checkEmailData: {
            status: status.SUCCESS,
            data: payload.data || {},
          },
        };
      })
      .addCase(checkEmail.rejected.toString(), (state, action) => {
        return {
          ...state,
          checkEmailData: {
            status: status.FAILURE,
            data: action.error,
          },
        };
      })

      .addCase(checkCompany.pending.toString(), (state, action) => {
        return {
          ...state,
          checkCompanyData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(checkCompany.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          checkCompanyData: {
            status: status.SUCCESS,
            data: payload.data || {},
          },
        };
      })
      .addCase(checkCompany.rejected.toString(), (state, action) => {
        return {
          ...state,
          checkCompanyData: {
            status: status.FAILURE,
            data: action.error,
          },
        };
      })

      .addCase(handleLogin.pending.toString(), (state, action) => {
        return {
          ...state,
          loggedInUser: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(handleLogin.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          loggedInUser: {
            status: status.SUCCESS,
            data: payload.data || {},
          },
        };
      })
      .addCase(handleLogin.rejected.toString(), (state, action) => {
        return {
          ...state,
          loggedInUser: {
            status: status.FAILURE,
          },
        };
      });
  },
});

export default authSlice.reducer;
